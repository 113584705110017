import { post } from '@/libs/request'

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || '',
        userInfo: null,
        characters: []
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
            localStorage.setItem('token', token)
        },
        SET_USER_INFO(state, userInfo) {
            state.userInfo = userInfo
        },
        SET_CHARACTERS(state, characters) {
            state.characters = characters
        },
        CLEAR_USER_STATE(state) {
            state.token = ''
            state.userInfo = null
            state.characters = []
            localStorage.removeItem('token')
        }
    },
    actions: {
        async login({ commit }, { username, password, ticket, randStr }) {
            try {
                const res = await post('/user/login', {
                    username,
                    password,
                    ticket,
                    randStr
                })
                if (res.success) {
                    commit('SET_TOKEN', res.data.token)
                    return res
                }
                return Promise.reject(res)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        logout({ commit }) {
            commit('CLEAR_USER_STATE')
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        userInfo: state => state.userInfo,
        characters: state => state.characters
    }
} 