export const characterClasses = {
    1: "野蛮人",
    2: "贵族",
    3: "女巫",
    4: "游侠",
    5: "决斗者",
    6: "圣堂武僧",
    7: "暗影刺客",
};

export const rangeTypes = {
    1: "单体",
    2: "田字",
    3: "横排",
    4: "竖排",
    5: "十字",
    6: "锥形",
    7: "光环",
    8: "自身",
    9: "半月弧形",
}

export const equipmentTypes = [
    {value: null, label: '全部'},
    {value: 32767, label: '所有武器'},
    {value: 511, label: '单手武器'},
    {value: 32256, label: '双手武器'},
    {value: 507, label: '单手近战武器'},
    {value: 31744, label: '双手近战武器'},
    {value: 211106232500224, label: '所有防具'},
    {value: 844424930131968, label: '所有饰品'},
    {value: 1, label: '单手剑'},
    {value: 2, label: '单手斧'},
    {value: 4, label: '法杖'},
    {value: 8, label: '爪'},
    {value: 16, label: '匕首'},
    {value: 32, label: '细剑'},
    {value: 64, label: '单手锤'},
    {value: 128, label: '短杖'},
    {value: 256, label: '符文匕首'},
    {value: 512, label: '弓'},
    {value: 1024, label: '长杖'},
    {value: 2048, label: '双手剑'},
    {value: 4096, label: '双手斧'},
    {value: 8192, label: '双手锤'},
    {value: 16384, label: '战杖'},
    {value: 2064384, label: '手套'},
    {value: 132120576, label: '鞋子'},
    {value: 17045651456, label: '胸甲'},
    {value: 1082331758592, label: '头部'},
    {value: 69269232549888, label: '盾牌'},
    {value: 70368744177664, label: '箭袋'},
    {value: 140737488355328, label: '腰带'},
    {value: 281474976710656, label: '项链'},
    {value: 562949953421312, label: '戒指'},
]

export const getEquipmentType = (value) => {
    if (value === 1125899906842623) return "所有装备"
    return equipmentTypes.find(item => item.value === value)?.label || '未知'
}

export const rarityClass = {
    0: 'player',
    1: 'normal',
    2: 'magic',
    3: 'rare',
    4: 'unique',
}

export const rarities = [
    {name: "普通", value: 1, class: 'r-normal'},
    {name: "魔法", value: 2, class: 'r-magic'},
    {name: "稀有", value: 3, class: 'r-rare'},
    {name: "独特", value: 4, class: 'r-unique'},
]

export const currencyTypes = [
    {name: "磨刀石", value: 16, tip: '提升武器品质', property: "whetstone"},
    {name: "护甲片", value: 17, tip: '提升防具品质', property: "armour"},
    {name: "工匠石", value: 1, tip: '重置一件物品上插槽的数量', property: "jewellerOrb"},
    {name: "幻色石", value: 2, tip: '重置一件物品上插槽的顏色', property: "chromaticOrb"},
    {name: "链结石", value: 3, tip: '重置一件物品上插槽的连接', property: "orbOfFusing"},
    {name: "蜕变石", value: 4, tip: '将一件普通物品升级成魔法物品', property: "orbOfTransmutation"},
    {name: "机会石", value: 5, tip: '将一件普通物品随机升级成魔法，稀有或传奇物品', property: "orbOfChance"},
    {name: "点金石", value: 6, tip: '将一个普通物品升级为稀有物品', property: "orbOfAlchemy"},
    {name: "增幅石", value: 7, tip: '用一个新随机词缀强化一个魔法物品', property: "orbOfAugmentation"},
    {name: "改造石", value: 8, tip: '重置一件魔法物品上的所有属性', property: "orbOfAlteration"},
    {name: "崇高石", value: 9, tip: '用一个新随机词缀强化一个稀有物品', property: "regalOrb"},
    {name: "剥离石", value: 18, tip: '随机移除魔法或稀有物品上的一条属性', property: "orbOfAnnulment"},
    {name: "混沌石", value: 10, tip: '用新的随机属性来重铸一个稀有物品', property: "chaosOrb"},
    {name: "富豪石", value: 11, tip: '将一件魔法物品升级成稀有物品', property: "exaltedOrb"},
    {name: "重铸石", value: 12, tip: '将一件物品上的所有属性删除，变回普通物品', property: "orbOfScouring"},
    {name: "神圣石", value: 13, tip: '重置一件物品上随机词缀的数值', property: "divineOrb"},
    {name: "瓦尔宝珠", value: 14, tip: '腐化一件装备并随机修改其属性', property: "vaalOrb"},
    {
        name: "破溃宝珠",
        value: 19,
        tip: '分裂一件具有至少 4 个词缀的稀有物品的一个随机词缀，使其锁定',
        property: "fracturingOrb"
    },
    {name: "卡兰德的魔镜", value: 15, tip: '复制一件指定物品', property: "mirrorOfKalandra"},
]

export const getCurrencyTooltip = (value) => {
    return currencyTypes.find(item => item.value === value)?.tip || ''
}

export const getCurrencyName = (value) => {
    return currencyTypes.find(item => item.value === parseInt(value))?.name || ''
}

export const skills = {
    Melee: {name: "普通攻击",},
    MeleeAtAnimationSpeed: {name: "普通攻击",},
    FemaleCannibalBossFlameDash: {name: "烈焰冲刺",},
    FireFuryFlameWhip: {name: "怒焰奔腾",},
    FireFurySummonExplodingSkeletons: {name: "召唤魔侍",},
    FemaleCannibalBossFireStorm: {name: "烈炎风暴",},
    MeleeAtAnimationSpeedFireUnique: {name: "普通攻击（火焰）",},
    MeleeAtAnimationSpeedUnique: {name: "普通攻击",},
    ScavengerThrow: {name: "投掷",},
    MonsterFlameRedCannibal: {name: "圣焰图腾",},
    HailrakeGlacialCascade: {name: "冰川之刺",},
    HailrakeArcticArmour: {name: "极地装甲",},
    HailrakeGlacialHammer: {name: "冰霜之锤",},
    WaterElementalCleave: {name: "劈砍（冰霜）",},
    WaterElementalMortar: {
        name: "炮击（冰霜）",
    },
    DoubleStrike: {name: "双重打击",},
    ZombieBossExtract: {name: "提取",},
    ZombieBossLeapSlam: {name: "跃击",},
    MMASandSplitterProjectile: {name: "沙子喷射",},
    Ground_Slam: {name: "裂地之击", type: 1,},
    Fireball: {name: "火球", type: 3,},
    Heavy_Strike: {name: "重击", type: 1,},
    Ice_Shot: {name: "冰霜射击", type: 2,},
    Chance_to_Bleed_Support: {name: "流血几率(辅)", type: 1},
    Life_Gain_on_Hit_Support: {name: "击中回复生命(辅)", type: 1},
    Frostbolt: {name: "寒冰弹", type: 3,},
    Burning_Arrow: {name: "燃烧箭矢", type: 2,},
    Volley_Support: {name: "齐射(辅)", type: 2,},
    Melee_Physical_Damage_Support: {name: "近战物理伤害(辅)", type: 1,},
    Added_Lightning_Damage_Support: {name: "附加闪电伤害(辅)", type: 3,},
    Added_Cold_Damage_Support: {name: "附加冰霜伤害(辅)", type: 2,},
    Added_Fire_Damage_Support: {name: "附加火焰伤害(辅)", type: 1,},
    Determination: {name: "坚定", type: 1},
    Grace: {name: "优雅", type: 2},
    Lightning_Arrow: {name: "闪电箭矢", type: 2},
    Sweep: {name: "横扫", type: 1},
    Precision: {name: "精准", type: 2},
    Multistrike_Support: {name: "多重打击(辅)", type: 1},
    Arc: {name: "电弧", type: 3},
    Increased_Critical_Strikes_Support: {name: "提高暴击(辅)", type: 3},
    Life_Leech_Support: {name: "生命偷取(辅)", type: 1},
    Increased_Critical_Damage_Support: {name: "提高暴击伤害(辅)", type: 3},
    Faster_Attacks_Support: {name: "快速攻击(辅)", type: 2},
    Lightning_Strike: {name: "闪电打击", type: 2},
    Spell_Echo_Support: {name: "法术回响(辅)", type: 3},
    Steelskin: {name: "钢铁之肤", type: 1},
    Chance_to_Poison_Support: {name: "低阶毒化(辅)", type: 2},
    Hatred: {name: "憎恨", type: 2},
    Double_Strike: {name: "双重打击", type: 2},
    Blazing_Salvo: {name: "怒炎穿心", type: 3},
    Empower_Support: {name: "赋予(辅)", type: 1},
    Culling_Strike_Support: {name: "终结(辅)", type: 2},
    Haste: {name: "迅捷", type: 2},
    Enlighten_Support: {name: "启蒙(辅)", type: 3},
    Wrath: {name: "雷霆", type: 3},
    Brutality_Support: {name: "残暴(辅)", type: 1},
    Split_Arrow: {name: "分裂箭矢", type: 2},
    Zealotry: {name: "奋锐光环", type: 3},
    Elemental_Damage_with_Attacks_Support: {name: "武器元素伤害(辅)", type: 1},
    Efficacy_Support: {name: "效能(辅)", type: 3},
    Elemental_Penetration_Support: {name: "元素穿透(辅)", type: 3},
    Fire_Penetration_Support: {name: "火焰穿透(辅)", type: 1},
    Cold_Penetration_Support: {name: "冰霜穿透(辅)", type: 2},
    Lightning_Penetration_Support: {name: "闪电穿透(辅)", type: 3},
    Anger: {name: "愤怒", type: 1},
    CleaveOfRage: {name: "怒火之劈砍", type: 1},
}


export const damageTypes = {
    1: "物理",
    2: "火焰",
    3: "冰霜",
    4: "闪电",
    5: "混沌",
}

export const magics = {
    1: (value) => `<div>物理伤害提高 ${value}%</div>`,
    2: (value) => `<div>该装备的能量护盾提高 ${value}%</div>`,
    3: (value) => `<div>晕眩回复和格挡回复提高 ${value}%</div>`,
    4: (value) => `<div>属性需求降低 ${value}%</div>`,
    5: (value) => `<div>每击败一名敌人获得 ${value} 点魔力</div>`,
    6: (value) => `<div>照亮范围扩大 ${value}%</div>`,
    7: (value) => `<div>+${value} 命中值</div>`,
    8: (value) => `<div>+${value} 全属性</div>`,
    9: (value) => `<div>攻击技能的元素伤害提高 ${value}%</div>`,
    10: (value) => `<div>物理攻击伤害的 ${value}% 会转化为生命偷取</div>`,
    11: (value) => `<div>物理攻击伤害的 ${value}% 会转化为魔力偷取</div>`,
    12: (value) => `<div>+${value} 力量</div>`,
    13: (value) => `<div>+${value} 敏捷</div>`,
    14: (value) => `<div>+${value} 智慧</div>`,
    15: (value) => `<div>攻击速度${upOrDown(value)} ${abs(value)}%</div>`,
    16: (value) => `<div>敌人晕眩门槛降低 ${value}%</div>`,
    17: (value) => `<div>+${value}% 火焰抗性</div>`,
    18: (value) => `<div>+${value}% 冰霜抗性</div>`,
    19: (value) => `<div>+${value}% 闪电抗性</div>`,
    20: (value) => `<div>+${value}% 混沌抗性</div>`,
    21: (value) => `<div>敌人被晕眩时间延长 ${value}%</div>`,
    22: (value) => `<div>每击中一名敌人获得 ${value} 点生命</div>`,
    23: (value) => `<div>每击败一名敌人获得 ${value} 点生命</div>`,
    24: (value) => `<div>每击中一名敌人获得 ${value} 点魔力</div>`,
    25: (value) => `<div>该装备的攻击暴击率提高 ${value}%</div>`,
    26: (value) => `<div>+${value}% 全域暴击伤害加成</div>`,
    27: (value) => `<div>命中值${upOrDown(value)} ${abs(value)}%</div>`,
    28: (value) => `<div>击中时有 ${value}% 的几率使目标中毒</div>`,
    29: (value) => `<div>击中时有 ${value}% 的几率使目标流血</div>`,
    30: (value) => `<div>中毒伤害提高 ${value}%</div>`,
    31: (value) => `<div>流血伤害提高 ${value}%</div>`,
    32: (value) => `<div>有 ${value}% 几率造成瘫痪</div>`,
    33: (value) => `<div>该武器击中后造成的 ${value}% 物理伤害转换为一种随机元素伤害</div>`,
    34: (value) => `<div>全域暴击率提高 ${value}%</div>`,
    35: (value) => `<div>${value}% 攻击伤害格挡率</div>`,
    36: () => `<div>用该武器击中时，所有伤害都导致中毒</div>`,
    37: (value) => `<div>法术伤害提高 ${value}%</div>`,
    38: (value) => `<div>攻击和法术附加 ${value[0]}-${value[1]} 基础火焰伤害</div>`,
    39: (value) => `<div>攻击和法术附加 ${value[0]}-${value[1]} 基础冰霜伤害</div>`,
    40: (value) => `<div>攻击和法术附加 ${value[0]}-${value[1]} 基础闪电伤害</div>`,
    41: (value) => `<div>攻击和法术附加 ${value[0]}-${value[1]} 基础混沌伤害</div>`,
    42: (value) => `<div>施法速度${upOrDown(value[0])} ${abs(value[0])}%（弃用）</div>`,
    43: () => `<div>每 6 秒获得一个耐力球、狂怒球或暴击球</div>`,
    44: (value) => `<div>召唤生物的伤害提高 ${value}%</div>`,
    45: (value) => `<div>全局物理伤害提高 ${value}%</div>`,
    46: (value) => `<div>+${value} 最大怒火</div>`,
    47: (value) => `<div>使用该武器近战击中时触发 ${value[0]} 级${value[1]}</div>`,
    48: (value) => `<div>元素伤害提高 ${value}%</div>`,
    49: (value) => `<div>伤害穿透 ${value}% 元素抗性</div>`,
    50: () => `<div>元素超载</div>`,
    51: () => `<div>元素之相</div>`,
    52: () => `<div>苦难秘辛</div>`,
    53: (value) => `<div>你使用插入的技能时触发 ${value[0]} 级${value[1]}</div>`,
    54: (value) => `<div>移动速度加快 ${value}%</div>`,
    55: (value) => `<div>持长杖时攻击伤害格挡几率 +${value}%</div>`,
    56: () => `<div>施放插入的技能时，在消耗魔力前先消耗能量护盾</div>`,
    57: (value) => `<div>+${value} 最大能量护盾</div>`,
    58: (value) => `<div>+${value} 力量和敏捷</div>`,
    59: () => `<div>暴击攻击无视敌人的混沌抗性</div>`,
    60: (value) => `<div>+${value}% 流血持续伤害加成</div>`,
    61: (value) => `<div>最大品质 +${value}%</div>`,
    62: (value) => `<div>效果区域扩大 ${value}%</div>`,
    63: (value) => `<div>范围伤害提高 ${value}%</div>`,
    64: (value) => `<div>有 ${value}% 的几率造成双倍伤害</div>`,
    65: (value) => `<div>有 ${value}% 的几率使晕眩时间延长 1 倍</div>`,
    66: (value) => `<div>战吼增助 ${value} 次额外攻击</div>`,
    67: (value) => `<div>力量提高 ${value}%</div>`,
    68: (value) => `<div>暴击球和耐力球上限 +${value}</div>`,
    69: (value) => `<div>有 ${value} 个插槽</div>`,
    70: (value) => `<div>攻击附加 ${value} 基础物理伤害</div>`,
    71: (value) => `<div>攻击附加 ${value} 基础火焰伤害</div>`,
    72: (value) => `<div>攻击附加 ${value} 基础冰霜伤害</div>`,
    73: (value) => `<div>攻击附加 ${value} 基础闪电伤害</div>`,
    74: (value) => `<div>攻击附加 ${value} 基础混沌伤害</div>`,
    75: (value) => `<div>你的攻击击中每个敌人会回复 ${value} 生命</div>`,
    76: (value) => `<div>投射物速度加快 ${value}%</div>`,
    77: (value) => `<div>箭矢会穿透 ${value} 个额外目标</div>`,
    78: (value) => `<div>攻击附加 ${value[0]} ~ ${value[1]} 基础物理伤害</div>`,
    79: (value) => `<div>攻击附加 ${value[0]} ~ ${value[1]} 基础火焰伤害</div>`,
    80: (value) => `<div>攻击附加 ${value[0]} ~ ${value[1]} 基础冰霜伤害</div>`,
    81: (value) => `<div>攻击附加 ${value[0]} ~ ${value[1]} 基础闪电伤害</div>`,
    82: (value) => `<div>攻击附加 ${value[0]} ~ ${value[1]} 基础混沌伤害</div>`,
    83: (value) => `<div>弓类攻击的暴击率提高 ${value}%</div>`,
    84: (value) => `<div>获得额外混沌伤害，其数值等同于物理伤害的 ${value}%</div>`,
    85: (value) => `<div>图腾放置速度加快 ${value}%</div>`,
    86: (value) => `<div>弓类攻击附加 ${value[0]} - ${value[1]} 点物理伤害</div>`,
    87: (value) => `<div>+ ${value} 最大生命</div>`,
    88: () => `<div>你格挡敌人的伤害时烧灼敌人</div>`,
    89: () => `<div>你格挡敌人的伤害时，给它们施加易碎</div>`,
    90: () => `<div>你格挡敌人的伤害时使它们力竭</div>`,
    91: (value) => `<div>格挡回复提高 ${value}%</div>`,
    92: (value) => `<div>+${value}% 所有元素抗性</div>`,
    93: (value) => `<div>法术伤害压制率 +${value}%</div>`,
    94: (value) => `<div>+${value}% 混沌抗性</div>`,
    95: (value) => `<div>增助攻击的伤害提高 ${value}%</div>`,
    96: (value) => `<div>战吼技能冷却时间 +${value} 秒</div>`,
    97: (value) => `<div>主手攻击伤害${upOrDown(value)} ${abs(value)}%</div>`,
    98: (value) => `<div>副手攻击伤害${upOrDown(value)} ${abs(value)}%</div>`,
    99: (value) => `<div>魔力上限${upOrDown(value)} ${abs(value)}%</div>`,
    100: (value) => `<div>你施放技能时有 ${value}% 的几率得到等于其花费的魔力</div>`,
    101: (value) => `<div>近战伤害提高 ${value}%</div>`,
    102: (value) => `<div>投射物攻击伤害提高 ${value}%</div>`,
    103: (value) => `<div>持续伤害提高 ${value}%</div>`,
    104: (value) => `<div>被格挡的攻击对你造成 ${value}% 伤害</div>`,
    105: (value) => `<div>每 ${value[0]} 点命中值都附加 ${value[1]} 到 ${value[2]} 点攻击闪电伤害</div>`,
    106: (value) => `<div>总命中值提升 ${value}%</div>`,
    107: (value) => `<div>位移技能的的冷却恢复速度加快 ${value}%</div>`,
    108: (value) => `<div>冰霜与闪电抗性提高 ${value}%</div>`,
    109: (value) => `<div>火焰与冰霜抗性提高 ${value}%</div>`,
    110: (value) => `<div>火焰与闪电抗性提高 ${value}%</div>`,
    111: (value) => `<div>+${value} 最大魔力</div>`,
    112: (value) => `<div>所有瓦尔主动技能石等级 +${value}</div>`,
    113: (value) => `<div>${value}% 额外物理伤害减免</div>`,
    114: () => `<div>你被碾压了</div>`,
    115: (value) => `<div>护身上限 +${value}</div>`,
    116: () => `<div>插入的技能击中时施加火焰、冰霜、闪电曝露效果</div>`,
    117: (value) => `<div>附加 ${value[0]}-${value[1]} 基础冰霜伤害</div>`,
    118: (value) => `<div>附加 ${value[0]}-${value[1]} 基础闪电伤害</div>`,
    119: (value) => `<div>附加 ${value[0]}-${value[1]} 基础混沌伤害</div>`,
    120: (value) => `<div>附加 ${value[0]}-${value[1]} 基础火焰伤害</div>`,
    121: (value) => `<div>你的击中将冰霜抗性视为比实际高 ${value}%</div>`,
    122: (value) => `<div>魔力再生率提高 ${value}%</div>`,
    123: (value) => `<div>生命每秒再生 ${value}</div>`,
    124: (value) => `<div>物品稀有度提高 ${value}%</div>`,
    125: (value) => `<div>+${value} 敏捷与智慧</div>`,
    126: (value) => `<div>+${value} 力量与智慧</div>`,
    127: () => `<div>基底词缀不能改变</div>`,
    128: () => `<div>具有裂界者、塑界者和所有征服者影响效果</div>`,
    129: (value) => `<div>允许的前缀 ${value >= 0 ? '+' : '-'}${abs(value)}</div>`,
    130: (value) => `<div>允许的后缀 ${value >= 0 ? '+' : '-'}${abs(value)}</div>`,
    131: (value) => `<div>外延词缀幅度提高 ${value}%</div>`,
    132: (value) => `<div>能量护盾充能时间提前 ${value}%</div>`,
    133: (value) => `<div>火焰伤害提高 ${value}%</div>`,
    134: (value) => `<div>冰霜伤害提高 ${value}%</div>`,
    135: (value) => `<div>闪电伤害提高 ${value}%</div>`,
    136: (value) => `<div>混沌伤害提高 ${value}%</div>`,
    137: (value) => `<div>+${value[0]} 【${value[1]}】数量上限</div>`,
    138: (value) => `<div>攻击与施法速度加快 ${value}%</div>`,
    139: (value) => `<div>生命每秒再生 ${value}%</div>`,
    140: (value) => `<div>${value}% 的几率使敌人受到冰冻，感电与点燃</div>`,
    141: (value) => `<div>全局防御提高 ${value}%</div>`,
    142: (value) => `<div>受到击中火焰伤害的 ${value}% 转为冰霜伤害</div>`,
    143: (value) => `<div>受到击中火焰伤害的 ${value}% 转为闪电伤害</div>`,
    144: (value) => `<div>受到击中冰霜伤害的 ${value}% 转为火焰伤害</div>`,
    145: (value) => `<div>受到击中冰霜伤害的 ${value}% 转为闪电伤害</div>`,
    146: (value) => `<div>受到击中闪电伤害的 ${value}% 转为冰霜伤害</div>`,
    147: (value) => `<div>受到击中闪电伤害的 ${value}% 转为火焰伤害</div>`,
    148: (value) => `<div>生命上限提高 ${value}%</div>`,
    149: (value) => `<div>投射物会穿透 ${value} 个额外目标</div>`,
    150: (value) => `<div>物品掉落数量提高 ${value}%</div>`,
    151: (value) => `<div>伤害${upOrDown(value)} ${abs(value)}%</div>`,
    152: (value) => `<div>击败敌人时有 ${value}% 的几率获得一个暴击球</div>`,
    153: (value) => `<div>击败敌人时有 ${value}% 的几率获得一个狂怒球</div>`,
    154: (value) => `<div>击败敌人有 ${value}% 的几率时获得耐力球</div>`,
    155: (value) => `<div>获得等同 ${value[0]}% 物理伤害的 ${value[1]} 个随机元素伤害</div>`,
    156: (value) => `<div>攻击伤害提高 ${value}%</div>`,
    157: (value) => `<div>所有属性提高 ${value}%</div>`,
    158: (value) => `<div>召唤生物获得 ${value}% 元素抗性</div>`,
    159: (value) => `<div>左边的戒指栏位：技能效果的持续时间${upOrDown(value)} ${abs(value)}%</div>`,
    160: (value) => `<div>右边的戒指栏位：技能效果的持续时间${upOrDown(value)} ${abs(value)}%</div>`,
    161: (value) => `<div>左边的戒指栏位：你受到的诅咒效果${enhanceOrReduce(value)} ${abs(value)}%</div>`,
    162: (value) => `<div>右边的戒指栏位：你受到的诅咒效果${enhanceOrReduce(value)} ${abs(value)}%</div>`,
    163: (value) => `<div>左边的戒指栏位：召唤生物受到的伤害${upOrDown(value)} ${abs(value)}%</div>`,
    164: (value) => `<div>右边的戒指栏位：召唤生物受到的伤害${upOrDown(value)} ${abs(value)}%</div>`,
    165: (value) => `<div>左边的戒指栏位：你处于异常状态的时间${upOrDown(value)} ${abs(value)}%</div>`,
    166: (value) => `<div>左边的戒指栏位：受到的冰霜伤害的 ${value}% 转化为火焰伤害</div>`,
    167: (value) => `<div>右边的戒指栏位：受到的火焰伤害的 ${value}% 转化为冰霜伤害</div>`,
    168: (value) => `<div>左边的戒指栏位：受到的火焰伤害的 ${value}% 转化为闪电伤害</div>`,
    169: (value) => `<div>右边的戒指栏位：受到的闪电伤害的 ${value}% 转化为火焰伤害</div>`,
    170: (value) => `<div>左边的戒指栏位：受到的闪电伤害的 ${value}% 转化为冰霜伤害</div>`,
    171: (value) => `<div>右边的戒指栏位：受到的冰霜伤害的 ${value}% 转化为闪电伤害</div>`,
    172: () => `<div>属性在裂隙内得到加倍</div>`,

    175: (value) => `<div>该装备的护甲提高 ${value}% </div>`,
    176: (value) => `<div>护甲提高 ${value}</div>`,
    177: (value) => `<div>对较近的目标造成的击中伤害提高最多 ${value}%</div>`,
    178: (value) => `<div>被辅助的近战攻击技能可额外重复 ${value} 次</div>`,
    179: (value) => `<div>被辅助技能的总近战攻击速度总增 ${value}%</div>`,
    // 157: (value) => `<div>伤害提高 ${value}%</div>`,
    // 158: (value) => `<div>伤害提高 ${value}%</div>`,
    // 159: (value) => `<div>伤害提高 ${value}%</div>`,
    180: (value) => `<div>${value}% 的几率点燃敌人</div>`,
    181: (value) => `<div>发射 ${value} 个额外投射物</div>`,
    182: (value) => `<div>冰缓敌人如同伤害总增 ${value}%</div>`,
    183: (value) => `<div>物理伤害的 ${value}% 转化为冰霜伤害</div>`,
    184: (value) => `<div>冰霜异常状态效果提高 ${value}%</div>`,
    185: (value) => `<div>击中敌人时召唤 ${value[0]} 个幻影弓手，攻击速度总降 ${value[1]}%, 伤害总降 ${value[2]}%，持续 ${value[3]} 秒，最多 ${value[4]} 个幻影弓手</div>`,
    186: (value) => `<div>${value}% 机率迴避攻击</div>`,
    187: (value) => `<div>护甲提高 ${value}%</div>`,
    188: (value) => `<div>附加 ${value}% 物理傷害的火焰傷害</div>`,
    189: (value) => `<div>附加 ${value}% 物理傷害的冰冷傷害</div>`,
    190: (value) => `<div>${upOrDown(value)} ${abs(value)}% 闪避值</div>`,
    191: (value) => `<div>反射 ${value} 伤害给近战攻击者</div>`,
    192: (value) => `<div>物理伤害在使用双手武器时提高 ${value}%</div>`,
    193: (value) => `<div>双手近战武器攻击造成的异常状态伤害提高 ${value}%</div>`,
    194: (value) => `<div>双手近战武器攻击的攻击速度提高 ${value}%</div>`,
    195: (value) => `<div>物理伤害在使用单手武器时提高 ${value}%</div>`,
    196: (value) => `<div>单手近战武器攻击造成的异常状态伤害提高 ${value}%</div>`,
    197: (value) => `<div>单手近战武器攻击的攻击速度提高 ${value}%</div>`,
    198: (value) => `<div>火焰抗性上限提高 ${value}%</div>`,
    199: (value) => `<div>持续伤害加成 ${value}%</div>`,
    200: (value) => `<div>该装备上的技能石等级 +${value}</div>`,
    201: (value) => `<div>此物品上装备的【近战技能石】等级 +${value}</div>`,
    202: (value) => `<div>混沌持续伤害加成 ${value}%</div>`,
    203: (value) => `<div>所有法术主动技能石等级 +${value}</div>`,
    204: (value) => `<div>法术附加 ${value[0]} ~ ${value[1]} 基础火焰伤害</div>`,
    205: (value) => `<div>法术附加 ${value[0]} ~ ${value[1]} 基础闪电伤害</div>`,
    206: (value) => `<div>法术附加 ${value[0]} ~ ${value[1]} 基础冰霜伤害</div>`,
    207: (value) => `<div>施法速度${upOrDown(value[0])} ${abs(value[0])}%</div>`,
    208: (value) => `<div>法术暴击率提高 ${value}%</div>`,
    209: (value) => `<div>有 ${value}% 的几率造成冻结状态</div>`,
    210: (value) => `<div>闪电伤害击中时有 ${value}% 的几率使敌人受到感电效果影响</div>`,
    211: (value) => `<div>燃烧伤害提高 ${value}% </div>`,
    212: (value) => `<div>弓类攻击发射 ${value} 支额外箭矢</div>`,
    213: (value) => `<div>+${value} 点闪避值</div>`,
    214: (value) => `<div>将所受伤害的 ${value}% 吸纳为生命</div>`,
    215: (value) => `<div>弓类技能伤害提高 ${value}%</div>`,
    216: (value) => `<div>弓类攻击 +${value}% 暴击伤害加成</div>`,
    217: (value) => `<div>攻击技能的持续伤害加成 ${value}%</div>`,
    218: (value) => `<div>生命再生率提高 ${value}%</div>`,
    219: (value) => `<div>能量护盾充能率提高 ${value}%</div>`,
    220: (value) => `<div>该装备的护甲与闪避提高 ${value}%</div>`,
    221: (value) => `<div>该装备的护甲与能量护盾提高 ${value}%</div>`,
    222: (value) => `<div>该装备的闪避与能量护盾提高 ${value}%</div>`,
    223: (value) => `<div>该装备的护甲、闪避和能量护盾提高 ${value}%</div>`,
    224: (value) => `<div>该装备 +${value}% 攻击格挡率</div>`,
    225: (value) => `<div>生命回复你格挡时 ${value}</div>`,
    226: (value) => `<div>${value}% 几率避免元素异常状态</div>`,
    227: (value) => `<div>受到的暴击伤害降低 ${value}%</div>`,
    228: (value) => `<div>冰霜抗性上限提高 ${value}%</div>`,
    229: (value) => `<div>闪电抗性上限提高 ${value}%</div>`,
    230: (value) => `<div>混沌抗性上限提高 ${value}%</div>`,
    231: (value) => `<div>全部抗性上限提高 ${value}%</div>`,
    232: (value) => `<div>法术伤害格挡几率 ${value}%</div>`,
    233: (value) => `<div>将物理伤害的 ${value}% 转化为火焰伤害</div>`,
    234: (value) => `<div>近战攻击的物理伤害总增 ${value}%</div>`,
    235: (value) => `<div>近战击中造成的流血和中毒伤害总增 ${value}%</div>`,
    236: (value) => `<div>额外获得 ${value}% 物理伤害，并转化为火焰伤害</div>`,
    237: () => `<div>无法被冰冻</div>`,
    238: (value) => `<div>最多可以击中目标周围的 ${value} 个额外敌人</div>`,
    239: (value) => `<div>感电敌人如同伤害总增 ${value}%</div>`,
    240: (value) => `<div>攻击伤害${upOrDown(value)} ${abs(value)}%</div>`,
    241: (value) => `<div>第一个重复的被辅助技能造成的伤害总增 ${value}%</div>`,
    242: (value) => `<div>第二个重复的被辅助技能造成的伤害总增 ${value}%</div>`,
    243: (value) => `<div>连锁弹射 ${value} 次</div>`,
    244: (value) => `<div>每剩 1 次连锁弹射，伤害总增 ${value}%</div>`,
    245: (value) => `<div>${value}% 的几率使敌人感电</div>`,
    246: (value) => `<div>闪电异常状态效果提高 ${value}%</div>`,
    247: (value) => `<div>提高暴击率 +${value}%</div>`,
    248: (value) => `<div>该装备物理伤害提高 ${value}%</div>`,
    249: (value) => `<div>${value}% 伤害会转化为生命偷取</div>`,
    250: () => `<div>若该近战攻击没有失手，则后续的闪电投射物无法被躲避</div>`,
    251: (value) => `<div>获得 ${value}% 物理伤害，并转化为冰霜伤害</div>`,
    252: (value) => `<div>冰霜伤害总增 ${value}%</div>`,
    253: (value) => `<div>${value}% 物理伤害转换为闪电伤害</div>`,
    254: (value) => `<div>该装备的闪避率提高 ${value}%</div>`,
    255: (value) => `<div>当图腾存在时，玩家攻击速度总增 ${value}%</div>`,
    256: (value) => `<div>该装备的闪避率提高 ${value}%</div>`,
    257: (value) => `<div>该装备附加 ${value[0]} - ${value[1]} 基础物理伤害</div>`,
    258: (value) => `<div>受到伤害增加 ${value}%</div>`,
    259: (value) => `<div>伤害穿透 ${value}% 的闪电抗性</div>`,
    260: (value) => `<div>被你感电的敌人速度减慢 ${value}%</div>`,
    261: (value) => `<div>用该武器击中时使敌人感电，如同伤害总增 ${value}%</div>`,
    262: () => `<div>没有插槽</div>`,
    263: (value) => `<div>${value}% 闪电伤害转化为混沌伤害</div>`,
    264: () => `<div>混沌伤害可以造成感电</div>`,
    265: (value) => `<div>最大感电效果 +${value}%</div>`,
    266: (value) => `<div>近战伤害对流血的敌人提高 ${value}%</div>`,
    267: (value) => `<div>暴击时有 ${value}% 的几率致盲敌人</div>`,
    268: () => `<div>暴击无法吸血</div>`,
    269: (value) => `<div>暴击时有 ${value}% 的几率造成流血</div>`,
    270: () => `<div>你的击中无法被闪避</div>`,
    271: (value) => `<div>反击附加 ${value[0]} - ${value[1]} 基础冰霜伤害</div>`,
    272: (value) => `<div>伤害${totalUpOrDown(value)} ${abs(value)}%</div>`,
    273: (value) => `<div>法术可重复 ${abs(value)} 次</div>`,
    274: (value) => `<div>施法速度${totalUpOrDown(value[0])} ${abs(value[0])}%</div>`,
    275: (value) => `<div>击中受到的伤害在生命和能量护盾承受之前，${value}% 优先由该增益效果承受</div>`,
    276: (value) => `<div>该增益效果能承受 ${value} 点伤害</div>`,
    277: (value) => `<div>攻击造成的物理伤害提高 ${value}%</div>`,
    278: (value) => `<div>攻击技能的异常状态伤害提高 ${value}%</div>`,
    279: (value) => `<div>投射物伤害提高 ${value}%</div>`,
    280: (value) => `<div>能量护盾上限提高 ${value}%</div>`,
    281: (value) => `<div>对流血敌人附加 ${value[0]} - ${value[1]} 基础物理伤害</div>`,
    282: (value) => `<div>连续攻击 ${value} 次</div>`,
    283: (value) => `<div>技能宝石等级提高 +${value}</div>`,
    284: (value) => `<div>击中生命小于 ${value}% 的敌人时，会将其秒杀</div>`,
    285: (value) => `<div>投射物可重复击中相同目标</div>`,
    286: (value) => `<div>近战攻击速度提高 +${value}%</div>`,
    287: (value) => `<div>技能的保留效能提高 ${value}%</div>`,
    288: (value) => `<div>法术伤害的暴击伤害加成 +${value}%</div>`,
    289: (value) => `<div>技能的魔力保留效能提高 ${value}%</div>`,
    290: (value) => `<div>弓类攻击的攻击速度加快 ${value}%</div>`,
    291: (value) => `<div>弓类攻击的命中值提高 ${value}%</div>`,
    292: (value) => `<div>弓类技能的持续伤害效果提高 ${value}%</div>`,
    293: (value) => `<div>法术技能的闪电伤害总增 ${value}%</div>`,
    294: (value) => `<div>消耗及保留加成 ${value}%</div>`,
    295: (value) => `<div>物理伤害总增 ${value}%</div>`,
    296: () => `<div>无法造成混沌伤害</div>`,
    297: () => `<div>无法造成元素伤害</div>`,
    298: (value) => `<div>近战物理伤害提高 ${value}%</div>`,
    299: (value) => `<div>法术伤害的 ${value}% 转化为能量护盾偷取</div>`,
    300: (value) => `<div>${value}% 的几率使敌人受到冰冻、感电和点燃</div>`,
    301: (value) => `<div>近战武器攻击造成的异常状态伤害提高 ${value}%</div>`,
    302: (value) => `<div>近战攻击暴击率提高 ${value}%</div>`,
    303: (value) => `<div>+${value}% 近战攻击暴击伤害加成</div>`,
    304: (value) => `<div>额外获得 ${value} 个箭矢</div>`,
    305: (value) => `<div>法术伤害总增 ${value}%</div>`,
    306: (value) => `<div>非召唤生物有 ${value[0]}% 几率在击中稀有或传奇敌人时，造成持续 ${value[1]} 秒奉献地面</div>`,
    307: (value) => `<div>持盾牌时施法速度加快 ${value}%</div>`,
    308: (value) => `<div>法术伤害在持盾牌时提高 ${value}%</div>`,
    309: (value) => `<div>你技能的非诅咒类光环效果提高 ${value}%</div>`,
    310: (value) => `<div>剑类攻击 +${value}% 暴击伤害加成</div>`,
    311: (value) => `<div>剑类攻击的暴击率提高 ${value}%</div>`,
    312: (value) => `<div>斧类物理伤害提高 ${value}%</div>`,
    313: (value) => `<div>斧类攻击造成的异常状态伤害提高 ${value}%</div>`,
    314: (value) => `<div>斧类攻击的攻击速度加快 ${value}%</div>`,
    315: (value) => `<div>剑类攻击的命中值提高 ${value}%</div>`,
    316: (value) => `<div>剑类攻击的攻击速度加快 ${value}%</div>`,
    317: (value) => `<div>剑类物理伤害提高 ${value}%</div>`,
    318: (value) => `<div>斧类攻击的暴击率提高 ${value}%</div>`,
    319: (value) => `<div>斧类攻击 +${value}% 暴击伤害加成</div>`,
    320: (value) => `<div>剑类攻击造成的异常状态伤害提高 ${value}%</div>`,
    321: (value) => `<div>剑类攻击造成的击中和异常状态伤害提高 ${value}%</div>`,
    322: (value) => `<div>斧类攻击造成的击中和异常状态伤害提高 ${value}%</div>`,
    323: (value) => `<div>剑类攻击 +${value} 点命中值</div>`,

    324: (value) => `<div>锤类或短杖攻击的攻击速度加快 ${value}%</div>`,
    325: (value) => `<div>锤类或短杖物理伤害提高 ${value}%</div>`,
    326: (value) => `<div>锤类或短杖攻击造成的异常状态伤害提高 ${value}%</div>`,
    327: (value) => `<div>锤类或短杖攻击造成的击中和异常状态伤害提高 ${value}%</div>`,
    328: (value) => `<div>锤类或短杖攻击的暴击率提高 ${value}%</div>`,
    329: (value) => `<div>锤类或短杖攻击 +${value}% 暴击伤害加成</div>`,
    330: (value) => `<div>锤类或短杖攻击 +${value} 点命中值</div>`,
    331: (value) => `<div>锤类或短杖攻击的命中值提高 ${value}%</div>`,
    332: (value) => `<div>锤类或短杖的元素伤害提高 ${value}%</div>`,

    333: (value) => `<div>长杖攻击造成的击中和异常状态伤害提高 ${value}%</div>`,
    334: (value) => `<div>持长杖时 +${value}% 全域暴击伤害加成</div>`,
    335: (value) => `<div>持长杖时，暴击率提高 ${value}%</div>`,
    336: (value) => `<div>长杖攻击的暴击率提高 ${value}%</div>`,
    337: (value) => `<div>持长杖时攻击伤害格挡几率 +${value}%</div>`,
    338: (value) => `<div>持长杖时法术伤害格挡几率 +${value}%</div>`,

    339: (value) => `<div>反射 ${value} 冰霜伤害给近战攻击者</div>`,

    340: (value) => `<div>长杖攻击的元素伤害提高 ${value}%</div>`,
    341: (value) => `<div>长杖攻击的命中值提高 ${value}%</div>`,
    342: (value) => `<div>长杖攻击 +${value}% 暴击伤害加成</div>`,
    343: (value) => `<div>持长杖时攻击速度加快 ${value}%</div>`,
    344: (value) => `<div>持长杖时施法速度加快 ${value}%</div>`,

    345: (value) => `<div>法杖攻击的元素伤害提高 ${value}%</div>`,
    346: (value) => `<div>持法杖时攻击速度加快 ${value}%</div>`,
    347: (value) => `<div>法杖攻击的命中值提高 ${value}%</div>`,
    348: (value) => `<div>法杖物理伤害提高 ${value}%</div>`,
    349: (value) => `<div>法杖攻击造成的异常状态伤害提高 ${value}%</div>`,
    350: (value) => `<div>伤害在持法杖时提高 ${value}%</div>`,
    351: (value) => `<div>法杖攻击的暴击率提高 ${value}%</div>`,
    352: (value) => `<div>法杖攻击 +${value}% 暴击伤害加成</div>`,
    353: (value) => `<div>法杖攻击造成的击中和异常状态伤害提高 ${value}%</div>`,
    354: (value) => `<div>获得额外闪电伤害，数值等同于法杖物理伤害的 ${value}%</div>`,

    355: (value) => `<div>持匕首时攻击速度加快 ${value}%</div>`,
    356: (value) => `<div>匕首攻击的命中值提高 ${value}%</div>`,
    357: (value) => `<div>匕首攻击的暴击率提高 ${value}%</div>`,
    358: (value) => `<div>匕首攻击 +${value}% 暴击伤害加成</div>`,
    359: (value) => `<div>匕首攻击造成的击中和异常状态伤害提高 ${value}%</div>`,
    360: (value) => `<div>匕首攻击造成的异常状态伤害提高 ${value}%</div>`,
    361: () => `<div>匕首攻击的暴击使敌人中毒</div>`,

    362: (value) => `<div>爪类攻击速度加快 ${value}%</div>`,
    363: (value) => `<div>爪类攻击的暴击率提高 ${value}%</div>`,
    364: (value) => `<div>爪类攻击 +${value}% 暴击伤害加成</div>`,
    365: (value) => `<div>爪类攻击造成的异常状态伤害提高 ${value}%</div>`,
    366: (value) => `<div>爪类攻击造成的击中和异常状态伤害提高 ${value}%</div>`,
    367: (value) => `<div>爪类攻击的命中值提高 ${value}%</div>`,

    368: (value) => `<div>持盾牌时攻击造成的物理伤害提高 ${value}%</div>`,
    369: (value) => `<div>持盾牌时，攻击造成的异常状态伤害提高 ${value}%</div>`,
    370: (value) => `<div>从盾牌获取的防御提高 ${value}%</div>`,
    371: (value) => `<div>持盾牌时攻击格挡率 +${value}%</div>`,
    372: (value) => `<div>持盾牌时造成的攻击伤害提高 ${value}%</div>`,
    373: (value) => `<div>持盾牌时攻击速度加快 ${value}%</div>`,
    374: (value) => `<div>持盾牌时 +${value}% 元素抗性</div>`,
    375: (value) => `<div>持盾牌时有 +${value}% 几率格挡法术伤害</div>`,
    376: (value) => `<div>从盾牌获取的能量护盾提高 ${value}%</div>`,

    377: (value) => `<div>双手近战武器的攻击暴击率提高 ${value}%</div>`,
    378: (value) => `<div>双手近战武器的攻击 +${value}% 暴击伤害加成</div>`,
    379: (value) => `<div>双手近战武器的攻击命中值提高 ${value}%</div>`,
    380: (value) => `<div>双手近战武器的攻击速度加快 ${value}%</div>`,
    381: (value) => `<div>双手近战武器攻击造成的击中和异常状态伤害提高 ${value}%</div>`,

    382: (value) => `<div>单手近战武器的攻击速度加快 ${value}%</div>`,
    383: (value) => `<div>单手武器的攻击伤害提高 ${value}%</div>`,
    384: (value) => `<div>单手武器攻击造成的异常状态伤害提高 ${value}%</div>`,
    385: (value) => `<div>单手武器的攻击速度加快 ${value}%</div>`,

    386: (value) => `<div>双持攻击的攻击速度加快 ${value}%</div>`,
    387: (value) => `<div>双持攻击的命中值提高 ${value}%</div>`,
    388: (value) => `<div>双持武器时，攻击技能的伤害提高 ${value}%</div>`,
    389: (value) => `<div>双持时攻击伤害格挡率 +${value}%</div>`,
    390: (value) => `<div>双持时，攻击技能造成的异常状态伤害提高 ${value}%</div>`,
    391: (value) => `<div>双持时施法速度加快 ${value}%</div>`,
    392: (value) => `<div>法术伤害在双持武器时提高 ${value}%</div>`,
    393: (value) => `<div>移动速度在双持武器时加快 ${value}%</div>`,
    394: (value) => `<div>双持时法术伤害格挡率 ${value}%</div>`,
    395: (value) => `<div>双持武器时，攻击暴击率提高 ${value}%</div>`,
    396: (value) => `<div>双持武器时，+${value}% 攻击暴击伤害加成</div>`,

    397: (value) => `<div>+${value}% 物理持续伤害加成</div>`,
    398: (value) => `<div>+${value}% 火焰持续伤害加成</div>`,
    399: (value) => `<div>+${value}% 中毒持续伤害加成</div>`,
    400: (value) => `<div>伤害型异常状态生效速度加快 ${value}%</div>`,
    401: (value) => `<div>攻击技能的持续火焰伤害加成 +${value}%</div>`,
    402: (value) => `<div>暴击造成异常状态时， +${value}% 持续伤害加成</div>`,
    403: (value) => `<div>你造成的点燃的伤害生效速度加快 ${value}%</div>`,
    404: (value) => `<div>闪电异常状态持续时间延长 ${value}%</div>`,

    405: (value) => `<div>+${value}% 闪电技能暴击伤害加成</div>`,
    406: (value) => `<div>对感电目标的暴击率提高 ${value}%</div>`,
    407: (value) => `<div>该装备的攻击速度提高 ${value}%</div>`,
    408: (value) => `<div>技能效果持续时间延长 ${value}%</div>`,
    409: (value) => `<div>持续性伤害总增 ${value}%</div>`,
    410: (value) => `<div>攻击技能的元素伤害总增 ${value}%</div>`,
    411: (value) => `<div>穿透 ${value}% 元素抗性</div>`,
    412: (value) => `<div>伤害穿透 ${value}% 火焰抗性</div>`,
    413: (value) => `<div>伤害穿透 ${value}% 冰霜抗性</div>`,
    414: (value) => `<div>伤害穿透 ${value}% 闪电抗性</div>`,
    415: (value) => `<div>伤害穿透 ${value}% 混沌抗性</div>`,
    416: (value) => `<div>武器造成的伤害穿透 ${value}% 元素抗性</div>`,
    417: (value) => `<div>攻击伤害穿透 ${value}% 元素抗性</div>`,
    418: (value) => `<div>武器造成的伤害穿透 ${value}% 冰霜抗性</div>`,
    419: (value) => `<div>武器造成的伤害穿透 ${value}% 闪电抗性</div>`,
    420: (value) => `<div>武器造成的伤害穿透 ${value}% 混沌抗性</div>`,
    421: (value) => `<div>武器造成的伤害穿透 ${value}% 火焰抗性</div>`,
    422: (value) => `<div>攻击技能的冰霜伤害提高 ${value}%</div>`,
    423: (value) => `<div>攻击技能的闪电伤害提高 ${value}%</div>`,
    424: (value) => `<div>攻击技能的混沌伤害提高 ${value}%</div>`,
    425: (value) => `<div>攻击技能的火焰伤害提高 ${value}%</div>`,
    426: (value) => `<div>压制 ${value}% 物理伤害减免</div>`,

    427: (value) => `<div>弓类的物理伤害提高 ${value}%</div>`,
    428: (value) => `<div>投射物攻击技能的暴击率提高 ${value}%</div>`,
    429: (value) => `<div>投射物攻击技能的暴击伤害加成 +${value}%</div>`,
    430: (value) => `<div>非伤害性异常状态效果提高 ${value}%</div>`,
    431: (value) => `<div>+${value}% 冰霜持续伤害加成</div>`,
    432: (value) => `<div>前缀无法被改变</div>`,
    433: (value) => `<div>后缀无法被改变</div>`,
    434: (value) => `<div>+${value} 该装备最大能量护盾</div>`,
    435: (value) => `<div>该装备护甲提高 ${value}</div>`,


    436: (value) => `<div>该装备攻击附加  ${value[0]} - ${value[1]} 基础火焰伤害</div>`,
    437: (value) => `<div>该装备攻击附加  ${value[0]} - ${value[1]} 基础冰霜伤害</div>`,
    438: (value) => `<div>该装备攻击附加  ${value[0]} - ${value[1]} 基础闪电伤害</div>`,
    439: (value) => `<div>该装备攻击附加  ${value[0]} - ${value[1]} 基础混沌伤害</div>`,
    440: (value) => `<div>攻击击中时获得 ${value} 点怒火</div>`,
    441: (value) => `<div>双持武器时，每把武器联合起来造成 ${value}% 伤害</div>`,
}

const abs = (value) => {
    if (value && typeof value == "number") return Math.abs(value)
    return value
}

const upOrDown = (value) => {
    if (!value || value >= 0 || typeof value != "number") return "提高"
    return "降低"
}

const enhanceOrReduce = (value) => {
    if (!value || value >= 0 || typeof value == "object") return "增强"
    return "减弱"
}

const totalUpOrDown = (value) => {
    if (!value || value >= 0 || typeof value == "object") return "总增"
    return "总降"
}

// 加快或减慢
const speedUpOrDown = (value) => {
    if (!value || value >= 0 || typeof value == "object") return "加快"
    return "减慢"
}