<template>
  <a-config-provider :theme="store.state.antTheme" hash-priority="high"
    :transformers="[legacyLogicalPropertiesTransformer]">
    <RouterView>

    </RouterView>
  </a-config-provider>
</template>

<script setup>
import { legacyLogicalPropertiesTransformer, message } from "ant-design-vue";
import { onMounted, ref } from "vue";
import { magics } from "@/libs/data";
import { useStore } from 'vuex'

message.config({
  top: `50px`,
  duration: 2,
  maxCount: 1,
  rtl: true,
});
const store = useStore()
const defaultTheme = ref({})
onMounted(() => {

  store.dispatch('applyTheme')
})



window.magics = magics

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--default-text-color);
  height: 100%;
  height: 100vh;
  height: 100svh;
  position: relative;
}
</style>
