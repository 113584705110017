import "./assets/css/main.scss";
import "./assets/scss/global.scss";
import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import Loading from './directives/loading';
import lazyPlugin from "vue3-lazy";
import store from './store'

const routes = [
    {
        path: "/",
        title: "Idle POE",
        component: () => import("./views/Home.vue"),
        children: [
            {
                path: "",
                component: () => import("./views/Main.vue"),
            },
            {
                path: "equipment-filter",
                component: () => import("./views/EquipmentFilter.vue"),
            },
            {
                path: "market",
                component: () => import("./views/Market.vue"),
            },
            {
                path: "update",
                component: () => import("./views/UpdateLog.vue"),
            },
            {
                path: "battle",
                component: () => import("./views/BattleView.vue"),
            },
            {
                path: "rank",
                component: () => import("./views/Rank.vue"),
            },
            {
                path: "skilltree",
                component: () => import("./views/SkillTree.vue"),
            },
            {
                path: "shop",
                component: () => import("./views/ShopView.vue"),
            },
            {
                path: "tactics",
                component: () => import("./views/Tactics.vue"),
            },
            {
                path: "view",
                component: () => import("./views/Camera.vue")
            }
        ],
    },
    { path: "/login", component: () => import("./views/Login.vue") },
    { path: "/register", component: () => import("./views/Register.vue") },
    {
        path: "/test/equipment",
        component: () => import("./views/EquipmentTest.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes, // `routes: routes` 的缩写
});
router.beforeEach((to, from, next) => {
    if (!localStorage.getItem("token")) {
        if (to.path !== "/login" && to.path !== "/register") {
            next("/login");
        }
    }
    next();
});
const app = createApp(App)
app.directive('loading', Loading)
app.use(Antd).use(
    lazyPlugin,
    {
        loading: '',
        error: '',
    }
).use(router).use(store).mount("#app");
