import { createStore } from 'vuex'
import user from './modules/user'
import { theme } from 'ant-design-vue'

export default createStore({
    state: {
        theme: localStorage.getItem('theme') || 'dark',
        antTheme: {},
        pixelFont: localStorage.getItem('pixel-font') ? localStorage.getItem('pixel-font') === 'true' : false,
    },
    mutations: {
        SET_THEME(state, theme) {
            state.theme = theme
            this.dispatch('applyTheme')
            localStorage.setItem('theme', theme)
        },
        SET_PIXEL_FONT(state, pixelFont) {
            state.pixelFont = pixelFont
            localStorage.setItem('pixel-font', pixelFont)
            document.body.classList.toggle('pixel-font', pixelFont)
        },
    },
    actions: {
        togglePixelFont({ commit, state }) {
            commit('SET_PIXEL_FONT', !state.pixelFont)
        },

        applyTheme({ commit, state }) {
            if (state.theme === 'light') {
                document.body.classList.add('light')
                document.body.classList.remove('dark')
                state.antTheme = {
                    algorithm: theme.defaultAlgorithm,
                    token: {
                        colorBgBase: '#ebebeb',
                        colorTextBase: '#1b1b1b',
                        lineType: 'dashed',
                        colorSplit: '#bbb',
                    }
                }
            } else {
                document.body.classList.remove('light')
                document.body.classList.add('dark')
                state.antTheme = {
                    algorithm: theme.darkAlgorithm,
                    token: {
                        colorBgBase: 'black',
                        colorTextBase: '#a38b63',
                        colorPrimary: 'red'
                    }
                }
            }
            document.body.classList.toggle('pixel-font', state.pixelFont)
        },
        setTheme({ commit }, theme) {
            if (theme === this.state.theme) return
            commit('SET_THEME', theme)
        },
        toggleTheme({ commit }) {
            const theme = document.body.classList.contains('light') ? 'dark' : 'light'
            commit('SET_THEME', theme)
        }
    },
    modules: {
        user
    }
}) 