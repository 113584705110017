const getHeaders = () => {
    const headers = {};
    if (localStorage.getItem("token")) {
        headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    }
    if (localStorage.getItem("character_id")) {
        headers["CharacterId"] = localStorage.getItem("character_id");
    }
    return headers;
};

const getToken = () => {
    return localStorage.getItem("token");
};

export const get = async (url, query) => {
    // remove empty query
    const headers = getHeaders();
    if (query) {
        Object.keys(query).forEach((key) => {
            if (!query[key] || query[key] === "") {
                delete query[key];
            }
        });
        if (Object.keys(query).length > 0) {
            url += "?" + new URLSearchParams(query).toString();
        }
    }
    return await fetch(`${process.env.VUE_APP_API}${url}`, {
        method: "GET",
        headers: headers,
    }).then((res) => {
        if (res.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
        } else if (res.status === 502) {
            return {
                success: false,
                message: "服务器维护中",
            }
        }
        return res.json();
    });
};

export const post = async (url, data, init) => {
    const headers = {
        "Content-Type": "application/json",
        ...getHeaders(),
    };
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
        ...init,
    };
    return await fetch(`${process.env.VUE_APP_API}${url}`, options).then((res) => {
        if (res.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
        } else if (res.status === 502) {
            return {
                success: false,
                message: "服务器维护中",
            }
        }
        return res.json();
    });
};


export const getStone = async (stoneId) => {
    return await get(`/skillstone/${stoneId}`)
}

export const getEquipment = async (equipmentId) => {
    return await get(`/equipment/${equipmentId}`)
}

export const getTokenInfo = function () {
    const token = getToken();
    if (!token) {
        return {};
    }
    const base64Url = token.split(".")[1];
    const base64 = encodeURIComponent(base64Url).replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(base64));
}